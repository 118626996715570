import { connect } from "react-redux"
import { selectUser, createLoadUserAction } from "../redux/reducers/user"
import { selectRole } from "../redux/reducers/authentication"
import { selectSelectedCourse } from "../redux/reducers/courses"
import { selectedOrganisation, createLoadingAction as loadOrganisations } from "../redux/reducers/organisations"
import { selectCurrentPage } from "../redux/reducers/pages"
import { selectChapter, selectChapters } from "../redux/reducers/chapters"
import { selectStory, selectStories } from "../redux/reducers/stories"

const mapDispatchToProps = {
    loadUser: createLoadUserAction,
    loadOrganisations
}

const mapStateToProps = (state) => {
    // Extract the story and chapter IDs from the URL
    const { pathname } = location;
    const pathParts = pathname.split("/");
    const storyId = pathParts[4];
    const chapterId = pathParts[6];

    return {
        user: selectUser(state),
        userRole: selectRole(state),
        course: selectSelectedCourse(state),
        organisation: selectedOrganisation(state),
        currentPage: selectCurrentPage(state),
        story: selectStory(state, storyId),
        stories: selectStories(state),
        chapter: selectChapter(state, chapterId),
        chapters: selectChapters(state),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)