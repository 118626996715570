const helpers = {
    getEditedProfileFields: (ob1, ob2) => {
        const difference = Object.keys(ob2).filter(key =>
            (ob1[key] !== ob2[key] && ([
                "first_name",
                "last_name",
                "phone_number",
                "linkedin_url",
                "skype",
                "description",
            ].indexOf(key) >= 0)))
        return difference
    },
}

export function objectsAreEqual(obj1, obj2) {
    if (typeof obj1 !== "object" || obj1 === null || typeof obj2 !== "object" || obj2 === null) {
        return false;
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (let key of keys1) {
        if (obj1[key] !== obj2[key]) {
            return false;
        }
    }

    return true;
}

export default helpers
